<template>
  <node
    :title="config.name"
    :show-error="showError"
    :content="content"
    :error-info="errorInfo"
    @selected="$emit('selected')"
    @delNode="$emit('delNode')"
    placeholder="设置审批人"
    :header-bgc="headerBgc"
    header-icon="el-icon-s-check"
  />
</template>

<script>
import Node from './Node.vue'

export default {
  name: "ApprovalNode",
  props:{
    config:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {Node},
  data() {
    return {
      showError: false,
      errorInfo: '',
      managerList: [ // 部门主管
        '直接主管',
        '二级主管',
        '三级主管',
        '四级主管',
        '五级主管',
        '六级主管',
        '七级主管',
        '八级主管',
        '九级主管',
        '十级主管',
      ]
    }
  },
  computed:{
    headerBgc() {
      if (this.$store.state.process.diagramMode === 'viewer') {
        return this.config.props.headerBgc
      } else {
        return 'linear-gradient(90deg, #FF9535 0%, #FFC452 100%)'
      }
    },
    content(){
      const config = this.config.props
      console.log('审批节点11',config);
      if (config.assignedType === "ASSIGN_USER" && config.assignedUser.length > 0){
        let texts = []
        const approvalType = config.mode === 'AND' ? '会签' : '或签'
        config.assignedUser.forEach(org => texts.push(org.dataName))
        this.showError = false
        return config.assignedUser.length >= 2 ? `${approvalType}: ${String(texts).replaceAll(',', '、')}` : `${String(texts).replaceAll(',', '、')}`
      } 
      else if(config.assignedType === 'LEADER' || config.assignedType === 'LEADER_TOP') {
        this.showError = false
        const level = config.assignedType === 'LEADER' ? config.leader.level : config.leaderTop.endLevel
        return  this.managerList[level - 1]
      }
      else {
        return '设置审批人'
      }
    },
    nodeMap() {
      return this.$store.state.process.nodeMap
    },
  },
  methods: {
    getFormItemById(id){
      return this.$store.state.process.design.formItems.find(item => item.id === id)
    },
    //校验数据配置的合法性
    validate(err){
      // 获取父节点
      let parentNode = this.nodeMap.get(this.config.parentId)
      // 指定人员时，是否有成员
      if(this.config.props.assignedType === 'ASSIGN_USER' && this.config.props.assignedUser.length === 0) {
        this.showError = true
        err.push(`请完善${parentNode.type === 'CONDITION' ? parentNode.name + '的' : ''}审批`)
        return err
      } else {
        this.showError = false
      }
    },
  },
  created(){
  }
}
</script>

<style scoped>

</style>
